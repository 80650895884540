<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Driver } from '@/@core/stores/company'
import { getDriversByCompanyId } from '@/@core/stores/company'
import { useDriversStore } from '@/@core/stores/driver'
import { track } from '@/boot/mixpanel'
import AddNewDriverDrawer from '@/views/apps/logistics/AddNewDriverDrawer.vue'
import UpdateDriverDrawer from '@/views/apps/logistics/UpdateDriverDrawer.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const searchQuery = ref('')
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const driverStore = useDriversStore()
const alert = ref<{ type: string; message: string } | null>(null)
const alertTimeout = ref<number | null>(null)
const isAddNewDriverDrawerVisible = ref(false)
const isUpdateDriverDrawerVisible = ref(false)
const selectedDriver = ref<Driver | null>(null)

const auth = JSON.parse(localStorage.getItem('auth') ?? '')
const companyId = auth?.user?.company?.id

const driversData = ref({
  drivers: [],
  totalDrivers: 0,
})

const filteredDrivers = computed(() => {
  if (!searchQuery.value)
    return driversData.value.drivers

  return driversData.value.drivers.filter((driver: Driver) =>
    driver.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  )
})

const drivers = computed((): Driver[] => filteredDrivers.value)
const totalDrivers = computed(() => filteredDrivers.value.length)

const headers = [
  { title: t('Name'), key: 'name' },
  { title: t('Phone Number'), key: 'phone' },
  { title: t('Email'), key: 'email' },
  { title: t('Actions'), key: 'actions', sortable: false },
]

const showAlert = (type: string, message: string, duration: number = 5000) => {
  alert.value = { type, message }

  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)

  alertTimeout.value = setTimeout(() => {
    alert.value = null
  }, duration) as unknown as number
}

const loadDrivers = async () => {
  if (!companyId)
    return

  try {
    const fetchedDrivers = await getDriversByCompanyId(companyId)

    driversData.value.drivers = fetchedDrivers
    driversData.value.totalDrivers = driversData.value.drivers.length
  }
  catch (error) {
    console.error('Error loading drivers:', error)
  }
}

const openUpdateDriverDrawer = (driver: Driver) => {
  selectedDriver.value = driver
  isUpdateDriverDrawerVisible.value = true
}

const addNewDriver = async (driverData: Omit<Driver, 'id' | 'company'>) => {
  try {
    const fullDriverData = {
      ...driverData,
    }

    await driverStore.register_company_driver(fullDriverData)

    await loadDrivers()
    showAlert('success', t('Driver added successfully.'))
    track('New company driver created')
  }
  catch (error) {
    const errorMessage = t('There was a problem adding the new driver. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error adding new driver:', error)
  }
}

const updateDriver = async (driverData: Partial<Driver> & { id: number }) => {
  try {
    const fullDriverData = {
      id: driverData.id,
      name: driverData.name || selectedDriver.value?.name || '',
      phone_number: driverData.phone_number || selectedDriver.value?.phone_number || '',
      email: driverData.email || '',
    }

    const response = await driverStore.update_company_driver(fullDriverData)

    if (response?.status === 500) {
      const errorMessage = t('There was a problem updating the driver. Please try again later.')

      showAlert('error', errorMessage)
      console.error('Error updating driver:', response)
    }
    else {
      await loadDrivers()
      showAlert('success', t('Driver updated successfully.'))
      track('Driver updated')
    }
  }
  catch (error: any) {
    const errorMessage = t('There was a problem updating the driver. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error updating driver:', error)
  }
}

const updateOptions = (options: any) => {
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const isMobile = ref(false)

onMounted(() => {
  loadDrivers()
  isMobile.value = window.innerWidth <= 768
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth <= 768
  })
})

onUnmounted(() => {
  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)
})

watch(isAddNewDriverDrawerVisible, newVal => {
  if (newVal)
    track('Driver Add Drawer Opened')
})

watch(isUpdateDriverDrawerVisible, newVal => {
  if (newVal)
    track('Driver Update Drawer Opened')
})
</script>

<template>
  <section>
    <VAlert
      v-if="alert"
      :type="alert.type"
      variant="tonal"
      class="mb-4"
    >
      {{ alert.message }}
    </VAlert>
    <VCard class="mb-6">
      <VToolbar style="background-color: #e6e6e6 !important;">
        <VToolbarTitle
          style="text-transform: uppercase;"
          class="d-flex justify-center ms-0"
        >
          <strong class="d-flex align-center">
            {{ t('Drivers') }}
          </strong>
        </VToolbarTitle>
      </VToolbar>
      <VCardText
        v-if="!isMobile"
        class="d-flex flex-wrap gap-4"
      >
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: t('All') },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <div style="inline-size: 15.625rem;">
            <AppTextField
              v-model="searchQuery"
              :placeholder="t('Drivers')"
            />
          </div>
          <VBtn
            prepend-icon="tabler-plus"
            @click="isAddNewDriverDrawerVisible = true"
          >
            {{ t('Add Drivers') }}
          </VBtn>
        </div>
      </VCardText>

      <VCardText
        v-else
        class="d-flex flex-wrap gap-1"
      >
        <div class="d-flex">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: t('All') },
            ]"
            style="inline-size: 5rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="d-flex align-center flex-wrap">
          <VBtn
            prepend-icon="tabler-plus"
            style="inline-size: 10.5rem;"
            @click="isAddNewDriverDrawerVisible = true"
          >
            {{ t('Add Drivers') }}
          </VBtn>
        </div>

        <div style="inline-size: 100%;">
          <AppTextField
            v-model="searchQuery"
            :placeholder="t('Drivers')"
          />
        </div>
      </VCardText>

      <VDivider />

      <div
        v-if="!isMobile"
        style="position: relative;"
      >
        <VDataTableServer
          v-model:items-per-page="itemsPerPage"
          v-model:page="page"
          :items="drivers"
          :items-length="totalDrivers"
          :headers="headers"
          class="text-no-wrap"
          @update:options="updateOptions"
        >
          <template #item.name="{ item }">
            <div>{{ item.name }}</div>
          </template>

          <template #item.phone="{ item }">
            <div class="d-flex align-center gap-x-4">
              <div class="d-flex flex-column">
                <h6 class="text-base">
                  <RouterLink
                    :to="{ name: 'apps-user-view-id', params: { id: item.id } }"
                    class="font-weight-medium text-link"
                  >
                    {{ item.phone_number }}
                  </RouterLink>
                </h6>
              </div>
            </div>
          </template>

          <template #item.email="{ item }">
            <div>{{ item.email || '-' }}</div>
          </template>

          <template #item.actions="{ item }">
            <IconBtn @click="openUpdateDriverDrawer(item)">
              <VIcon icon="tabler-pencil" />
            </IconBtn>
          </template>

          <template #bottom>
            <TablePagination
              v-model:page="page"
              :items-per-page="itemsPerPage"
              :total-items="totalDrivers"
            />
          </template>
        </VDataTableServer>
      </div>
      <div v-else>
        <VCard
          v-for="driver in drivers"
          :key="driver.name"
          class="driver-card"
        >
          <div class="card-header">
            <VCardTitle
              class="driver-card-title"
              style="max-inline-size: 210px;"
            >
              {{ driver.name }}
            </VCardTitle>
            <VBtn
              prepend-icon="tabler-pencil"
              class="edit-button"
              @click="openUpdateDriverDrawer(driver)"
            />
          </div>
          <VCardText class="driver-card-text">
            <p>
              <strong>
                <VIcon icon="tabler-phone" />
              </strong>
              {{ driver.phone_number }}
            </p>
            <p>
              <strong>
                <VIcon icon="tabler-mail" />
              </strong>
              {{ driver.email || t('No email') }}
            </p>
          </VCardText>
        </VCard>
      </div>
    </VCard>

    <AddNewDriverDrawer
      v-model:isDrawerOpen="isAddNewDriverDrawerVisible"
      @driver-data="addNewDriver"
    />
    <UpdateDriverDrawer
      v-model:isDrawerOpen="isUpdateDriverDrawerVisible"
      :driver="selectedDriver"
      @driver-data="updateDriver"
    />
  </section>
</template>

<style lang="scss">
.driver-card {
  position: relative;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
  margin-block: 1rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.driver-card-text {
  padding: 0;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 0.5rem;
}

.driver-card-title {
  flex-grow: 1;
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  block-size: 40px;
  inline-size: 40px;
  min-inline-size: 40px !important;
  padding-inline: 10px 0;
}
</style>
